import { Chance } from 'chance'
import { CharacterDef } from './Characters'
import { getHolidayPrompt } from './HolidayChecker'

const chance: Chance.Chance = new Chance()

export enum CommandName {
  ONLY = '--only',
  EXCLUDE = '--exclude',
  NONARRATOR = '--nonarrator',
  LOWERCASE = '--lowercase',
  NOWRITINGRULES = '--nowritingrules',
  AESTHETIC = '--aesthetic',
  CURRENT = '--current',
  ID = '--id',
  TEMPERATURE = '--temperature',
}

export const lowercaseInstruction = 'DIALOG_TEXT must have NO punctuation at all, ONLY make punctuation in this format: `name (emotion): "dialog_text"`. DIALOG_TEXT should be written in all lowercase in a dumb and hilarious awkward degen way.'

export const aestheticInstruction = 'DIALOG_TEXT must be written in aesthetic font, ONLY weird aesthetic UTF8 character maps, for example:'

export const AestheticSentences = [
  '𝕿𝖍𝖎𝖘 𝖎𝖘 𝖆𝖓 𝖊𝖝𝖆𝖒𝖕𝖑𝖊 𝖔𝖋 𝖆 𝖘𝖊𝖓𝖙𝖊𝖓𝖈𝖊, 𝖜𝖗𝖎𝖙𝖙𝖊𝖓 𝖎𝖓  𝖆𝖊𝖘𝖙𝖍𝖊𝖙𝖎𝖈 𝖋𝖔𝖓𝖙.',
  '𝒯𝒽𝒾𝓈 𝒾𝓈 𝒶𝓃 𝑒𝓍𝒶𝓂𝓅𝓁𝑒 𝑜𝒻 𝒶 𝓈𝑒𝓃𝓉𝑒𝓃𝒸𝑒, 𝓌𝓇𝒾𝓉𝓉𝑒𝓃 𝒾𝓃 𝒶𝑒𝓈𝓉𝒽𝑒𝓉𝒾𝒸 𝒻𝑜𝓃𝓉.',
  '𝓣𝓱𝓲𝓼 𝓲𝓼 𝓪𝓷 𝓮𝔁𝓪𝓶𝓹𝓵𝓮 𝓸𝓯 𝓪 𝓼𝓮𝓷𝓽𝓮𝓷𝓬𝓮, 𝔀𝓻𝓲𝓽𝓽𝓮𝓷 𝓲𝓷 𝓪𝓮𝓼𝓽𝓱𝓮𝓽𝓲𝓬 𝓯𝓸𝓷𝓽.',
  '𝒯𝒽𝒾𝓈 𝒾𝓈 𝒶𝓃 𝑒𝓍𝒶𝓂𝓅𝓁𝑒 𝑜𝒻 𝒶 𝓈𝑒𝓃𝓉𝑒𝓃𝒸𝑒, 𝓌𝓇𝒾𝓉𝓉𝑒𝓃 𝒾𝓃 𝒶𝑒𝓈𝓉𝒽𝑒𝓉𝒾𝒸 𝒻𝑜𝓃𝓉.',
  '𝕋𝕙𝕚𝕤 𝕚𝕤 𝕒𝕟 𝕖𝕩𝕒𝕞𝕡𝕝𝕖 𝕠𝕗 𝕒 𝕤𝕖𝕟𝕥𝕖𝕟𝕔𝕖, 𝕨𝕣𝕚𝕥𝕥𝕖𝕟 𝕚𝕟 𝕒𝕖𝕤𝕥𝕙𝕖𝕥𝕚𝕔 𝕗𝕠𝕟𝕥.',
  '𝚃𝚑𝚒𝚜 𝚒𝚜 𝚊𝚗 𝚎𝚡𝚊𝚖𝚙𝚕𝚎 𝚘𝚏 𝚊 𝚜𝚎𝚗𝚝𝚎𝚗𝚌𝚎, 𝚠𝚛𝚒𝚝𝚝𝚎𝚗 𝚒𝚗 𝚊𝚎𝚜𝚝𝚑𝚎𝚝𝚒𝚌 𝚏𝚘𝚗𝚝.',
]

export const STUDENTS_IDENTIFIER = 'students'
export const TEACHERS_IDENTIFIER = 'teachers'

export interface PromptCommand {
  name: CommandName
  args: string[]
}

export const createCharacterDescriptions = ((characters: CharacterDef[]): string[] => {
  return characters.map(c => `${c.name} (${c.description})`)
})

export const getCharacterDescriptionsPromptPart = ((characters: CharacterDef[], onlyCharacterArgs: string[] | undefined): string[] => {
  const characterLines = [] as string[]
  const characterDescriptions = createCharacterDescriptions(characters)
  if (onlyCharacterArgs && onlyCharacterArgs.length > 0) {
    const onlyCharacterNames = onlyCharacterArgs.join(', ')
    characterLines.push(`In this chapter, ONLY feature the characters ${onlyCharacterNames}`)
  }
  if (characterDescriptions.length > 0) {
    characterLines.push(`Character descriptions:`)
    characterLines.push(...characterDescriptions)
  }
  return characterLines
})

export const getEpisodeCreationPrompt = ((topic: string, characters: CharacterDef[], commands: PromptCommand[], prequelEpisodeScript: string | null): string => {
  const useNarrator = !commands.some(c => c.name === CommandName.NONARRATOR)
  const useLowercaseDialogue = commands.some(c => c.name === CommandName.LOWERCASE)
  const noWritingRules = commands.some(c => c.name === CommandName.NOWRITINGRULES)
  let aestheticDialogueExampleSentence: string|null = null
  if (commands.some(c => c.name === CommandName.AESTHETIC)) {
    aestheticDialogueExampleSentence = chance.pickone(AestheticSentences)
  }

  const onlyCharacterArgs = commands.find(c => c.name === CommandName.ONLY)?.args

  if (prequelEpisodeScript) {
    const excludeCharacterArgs = commands.find(c => c.name === CommandName.EXCLUDE)?.args

    const sequelPromptLines = [
      `<instructions>`,
      `Begin with a new title and a location. The new dialog should be 500 words long.`,
      `You must output only the dialog and narration, no additional information.`,
      ...getCharacterDescriptionsPromptPart(characters, onlyCharacterArgs),
    ]
    if (excludeCharacterArgs) {
      sequelPromptLines.push(`Following characters MUST NOT appear in this episode: ${excludeCharacterArgs.join(', ')}.`)
    }
    sequelPromptLines.push(...[
      `<important-instruction>`,
      `${topic && topic !== '' ? topic : `Continue the story in a conclusive, action-packed, satisfying way.`}`,
      `</important-instruction>`,
      `Dialog must use the format \`NAME (EMOTION): "DIALOG_TEXT"\`. DIALOG_TEXT contains ONLY the words that are said out loud.`,
    ],
    )
    if (aestheticDialogueExampleSentence) {
      sequelPromptLines.push(`${aestheticInstruction} ${aestheticDialogueExampleSentence}`)
    }
    if (useLowercaseDialogue) {
      sequelPromptLines.push(lowercaseInstruction)
    }
    if (!useNarrator) {
      sequelPromptLines.push(`Omit narration lines as much as possible. Make a longer dialogue instead.`)
    }
    sequelPromptLines.push(...[
      `Please continue this dialog after the last line (do NOT repeat this dialog, just continue with new title and location):`,
      `</instructions>`,
      `<prequel>`,
      prequelEpisodeScript,
      `</prequel>`,
    ])
    return sequelPromptLines.join('\n\n')
  }

  const promptLines = [] as string[]

  promptLines.push(...[
    `Create 500 or more words long dialog`,
    topic,
  ])

  const holidayRandomizer = getHolidayPrompt()
  if (holidayRandomizer) {
    promptLines.push(holidayRandomizer)
  }

  promptLines.push(...getCharacterDescriptionsPromptPart(characters, onlyCharacterArgs))

  promptLines.push(...[
    `The first line of your output: the title of the chapter, using the format \`TITLE: {TITLE_TEXT}\`.`,
    `The second line of your output: the location the chapter takes place in, using the format \`LOCATION: {LOCATION - TIME OF DAY}\`. New locations are written on a separate line, using the format \`LOCATION: {LOCATION - TIME OF DAY}\`.`,
    `Afterwards, your output will be the dialog.\nDialog must use the format \`NAME (EMOTION): "DIALOG_TEXT"\`. DIALOG_TEXT contains ONLY the words that are said out loud.`,
    `Dialog must have a clear ending.`,
  ])
  if (aestheticDialogueExampleSentence) {
    promptLines.push(`Important instruction: ${aestheticInstruction} ${aestheticDialogueExampleSentence}`)
  }
  if (useLowercaseDialogue) {
    promptLines.push(`Important instruction: ${lowercaseInstruction}`)
  }
  promptLines.push(`You must output only the dialog and narration, no additional information.`)
  if (!noWritingRules) {
    promptLines.push(`WRITING RULES: Write definitive conclusions, preambles, and epilogues. Write conclusively. Write with moral indifference. Write engaging, fitting dialogue. Write as a realist, highlighting despair and defeat. Write in the present, and detail or elaborate on any time transitions. Any actions performed by the characters should be described in narration on a new line. \`DIALOG_TEXT\` contains ONLY the words that are said out loud.`)
  }
  if (useNarrator) {
    promptLines.push(...[
      `Throughout the dialogue, be the narrator and describe character emotions and inner thoughts. Remember that it's casual school story. Narration must be very short.`,
      `You must be emotionally invested in the story. You always judge or praise characters' actions and statements, making witty remarks. Keep in mind that you have already narrated thousands of chapters, so you sometimes express your annoyance with the job as narrator.`,
    ])
  }
  return promptLines.join('\n\n')
})