import { ClothingStyle } from './ClothingStyle'
import { EpisodesRow } from './EpisodesRow'
import { NonEmptyArray, SceneDef, ScriptLineType } from './Types'
import { Chance } from 'chance'

export interface CharacterSprite {
  name: string
  image: string
  width: number
  height: number
  aliases: string[]
}

export enum CharacterType {
  STUDENT = 'student',
  TEACHER = 'teacher'
}

export interface CoquiVoiceDef {
  speakerId: string
  speed: number
  pitch: number
}

export interface CharacterDef {
  id: CharacterId,
  name: string
  pronoun: string
  type: CharacterType
  aliases: string[]
  description: string
  shortDescription: string
  coquiVoice: CoquiVoiceDef
  sprites: Record<ClothingStyle, CharacterSprite[]>
}

export const episodeCharacters = (
  episode: EpisodesRow,
): CharacterDef[] => {
  if (episode.character_ids) {
    return CHARACTERS.filter(ch => episode.character_ids?.includes(ch.id))
  }

  const characters: CharacterDef[] = []
  for (const line of episode.script || []) {
    if (line.type !== ScriptLineType.DIALOG) {
      continue
    }

    const ch = findCharacter(line.data.actor, CHARACTERS)
    if (ch && !characters.includes(ch)) {
      characters.push(ch)
    }
  }
  return characters
}

export const findCharacter = (
  name: string,
  characters: CharacterDef[] = CHARACTERS,
): CharacterDef | null => {
  const nameLower = name.toLowerCase()
  for (const ch of characters) {
    if (ch.id.toLowerCase() === nameLower) {
      return ch
    }
    if (ch.name.toLowerCase() === nameLower) {
      return ch
    }
    if (ch.aliases.some(alias => alias.toLowerCase() === nameLower)) {
      return ch
    }
  }
  return null
}

const isMentionedCharacter = (ch: CharacterDef, message: string): boolean => {
  const messageLowercase = message.toLowerCase()

  if (messageLowercase.includes(ch.name.toLowerCase())) {
    return true
  }

  if (ch.aliases.some(alias => messageLowercase.includes(alias.toLowerCase()))) {
    return true
  }

  return false
}

export const episodeHasCharacter = (
  episode: EpisodesRow | null,
  characterId: CharacterId,
): boolean => {
  if (!episode || !episode.script) {
    return false
  }
  const episodeCharacterIds = episode.character_ids || CHARACTERS.map(ch => ch.id)

  return episode.script.some(line => {
    if (line.type !== ScriptLineType.DIALOG) {
      return false
    }
    const ch = findCharacter(line.data.actor || '')
    if (!ch) {
      return false
    }
    return ch.id === characterId && episodeCharacterIds.includes(ch.id)
  })
}

export const findMentionedCharacters = (characters: CharacterDef[], message: string): CharacterDef[] => {
  return characters.filter(ch => isMentionedCharacter(ch, message))
}

export const findSprite = (ch: CharacterDef | null, emotion: string | undefined, scene: SceneDef): CharacterSprite | null => {
  if (!ch) {
    return null
  }
  const clothingStyle = scene.clothingStyle ?? ClothingStyle.SCHOOL
  const sprites = ch.sprites[clothingStyle] ?? ch.sprites[ClothingStyle.SCHOOL]
  return sprites.find(s => s.name === emotion)
    || sprites.find(s => s.name === 'neutral')
    || null
}

const defineSprites = (ds: { style: ClothingStyle, dir: string }[], fn: (dir: string) => CharacterSprite[]) => {
  const sprites: Record<string, CharacterSprite[]> = {}
  ds.forEach(d => {
    sprites[d.style] = fn(d.dir)
  })
  return sprites
}

export const FALLBACK_VOICE_COQUI: CoquiVoiceDef = {
  speakerId: 'p247',
  speed: 1,
  pitch: 1,
}

export const NARRATOR_VOICE_COQUI: CoquiVoiceDef = {
  speakerId: 'narrator',
  speed: 1,
  pitch: 1,
}

export const ORIGAMI_HYPER_TEMPO = 1.75
export const YATO_DEMON_PITCH = .65

export enum CharacterId {
  MAESTRO = 'maestro',
  YATO = 'yato',
  BLOOP = 'bloop',
  JOHN = 'john',
  KAYA = 'kaya',
  AYAMI = 'ayami',
  KIRINO = 'kirino',
  ORIGAMI = 'origami',
  ZERASU = 'zerasu',
  MIKIKO = 'mikiko',
  KERMITO = 'kermito',
  MURI = 'muri',
  LUMI = 'lumi',
  YUVAN = 'yuvan',
  STEVE = 'steve',
}

export const CHARACTERS: NonEmptyArray<CharacterDef> = [
  {
    id: CharacterId.MAESTRO,
    name: 'Maestro',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: [],
    description: 'Maestro is a wise old owl and a Music History Professor who is excessively critical of his students, stubborn and resistant to change. Maestro speaks in rhymes, but he never breaks his rhymes up into separate lines. Maestro has high standards and is difficult to impress. Maestro impresses everyone with his encyclopedic knowledge on everything regarding music. Maestro likes to reminisce about his youth where he was part of a famous boy band. Maestro secretly loves breakcore and songs with high pitched vocals, but tries to convince everyone otherwise. Maestro likes to engage in behavior that isn\'t typical for a professor, like cosplaying vocaloids and spraying graffiti, but he tries to keep it a secret, though he often fails and gets very embarrassed.',
    shortDescription: 'a music history professor who has been in a boy band in his youth and loves taboo activities',
    coquiVoice: {
      speakerId: 'maestro',
      speed: 1,
      pitch: 1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'maestro' },
    ], (dir) => [
      { name: 'stern', image: dir + '/stern.webp', width: 506, height: 1000, aliases: ['indignantly', 'defensively', 'disapprovingly', 'serious'] },
      { name: 'angry', image: dir + '/angry.webp', width: 506, height: 1000, aliases: [] },
      { name: 'blush', image: dir + '/blush.webp', width: 506, height: 1000, aliases: [] },
      { name: 'disappointed', image: dir + '/disappointed.webp', width: 506, height: 1000, aliases: ['sighing'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 506, height: 1000, aliases: ['in embarrassment'] },
      { name: 'excited', image: dir + '/excited.webp', width: 506, height: 1000, aliases: ['surprised'] },
      { name: 'nervous', image: dir + '/nervous.webp', width: 506, height: 1000, aliases: ['cautiously'] },
      { name: 'neutral', image: dir + '/neutral.webp', width: 506, height: 1000, aliases: ['interested', 'impressed', 'skeptically', 'cautious', 'skeptical'] },
      { name: 'passionate', image: dir + '/passionate.webp', width: 506, height: 1000, aliases: [] },
      { name: 'rolling his eyes', image: dir + '/rolling eyes.webp', width: 506, height: 1000, aliases: [] },
      { name: 'smile', image: dir + '/smile.webp', width: 506, height: 1000, aliases: ['happily', 'amused', 'smiling', 'secretly pleased'] },
    ]),
  },
  {
    id: CharacterId.KAYA,
    name: 'Kaya',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['kaya-san', 'kaya-chan', 'kaya san', 'kaya chan'],
    description: 'Kaya is a bunny-girl who loves romance movies and dramas, and is a social butterfly. Kaya indentifies as a bisexual girl and is open about her preferences, which sometimes lands her in awkward situations. Kaya flirts with people around her, especially other female students. Kaya likes to seduce, banter and charm all the characters around her. Kaya often assumes characters around her are secret couples, and asks them questions that make them embarrassed, because Kaya is often right in her assumptions. Kaya\'s passion for romance makes her neglect responsibilities and annoy others. Kaya\'s hobby is cross-stitching. She has an irrational fear of cosplayers. Kaya is very interested in sexy clothing and cute make up. Kaya likes to put make up on other characters around her, including the male professors.',
    shortDescription: 'a confident female bunny student who likes putting make up on everyone and shipping people around her',
    coquiVoice: {
      speakerId: 'p270',
      speed: 0.95,
      pitch: 1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'kaya/school uniform' },
      { style: ClothingStyle.CASUAL, dir: 'kaya/casual' },
      { style: ClothingStyle.DATE, dir: 'kaya/dress' },
    ], (dir) => [
      { name: 'afraid', image: dir + '/afraid.webp', width: 600, height: 1000, aliases: ['worried'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 600, height: 1000, aliases: [] },
      { name: 'confident', image: dir + '/confident.webp', width: 600, height: 1000, aliases: [] },
      { name: 'disappointed', image: dir + '/disappointed.webp', width: 600, height: 1000, aliases: [] },
      { name: 'enthusiastic', image: dir + '/enthusiastic.webp', width: 600, height: 1000, aliases: ['excitedly', 'excited'] },
      { name: 'neutral', image: dir + '/calm.webp', width: 600, height: 1000, aliases: ['gratefully', 'intrigued'] },
      { name: 'calm', image: dir + '/happy.webp', width: 600, height: 1000, aliases: [] },
      { name: 'flirty', image: dir + '/flirty.webp', width: 600, height: 1000, aliases: ['teasingly'] },
      { name: 'giggling', image: dir + '/giggling.webp', width: 600, height: 1000, aliases: [] },
      { name: 'hesitant', image: dir + '/hesitant.webp', width: 600, height: 1000, aliases: [] },
      { name: 'hopeless', image: dir + '/hopeless.webp', width: 600, height: 1000, aliases: [] },
      { name: 'in love', image: dir + '/in love.webp', width: 600, height: 1000, aliases: [] },
      { name: 'mad', image: dir + '/mad.webp', width: 600, height: 1000, aliases: [] },
      { name: 'playful', image: dir + '/playful.webp', width: 600, height: 1000, aliases: [] },
      { name: 'pleased', image: dir + '/pleased.webp', width: 600, height: 1000, aliases: [] },
      { name: 'pouting', image: dir + '/pouting.webp', width: 600, height: 1000, aliases: [] },
      { name: 'sad', image: dir + '/sad.webp', width: 600, height: 1000, aliases: [] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 600, height: 1000, aliases: ['surprised'] },
      { name: 'winking', image: dir + '/winking.webp', width: 600, height: 1000, aliases: [] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 600, height: 1000, aliases: [] },
      { name: 'puzzled', image: dir + '/puzzled.webp', width: 600, height: 1000, aliases: [] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 600, height: 1000, aliases: [] },
      { name: 'curious', image: dir + '/enthusiastic.webp', width: 600, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.BLOOP,
    name: 'Bloop',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['bloop-sensei', 'bloop sensei'],
    description: 'Bloop is a male biology and chemistry professor, he loves to make puns about water-related topics. Bloop is an extraterrestrial life form from a water-covered planet, highly intelligent and curious. Bloop has a terrible sense of direction and often gets lost, despite being highly intelligent in other areas. Bloop has a secret obsession with human culture and collects random items he finds on Earth, like a traffic cone or a paperclip. Bloop also has a unique ability to communicate with marine life. Bloop relies on his advanced equipment for his work, but it often malfunctions in unexpected ways, causing him frustration and hilarity.',
    shortDescription: 'is an male professor who is a water-based alien creature with a fascination for human objects',
    coquiVoice: {
      speakerId: 'p251',
      speed: 0.85,
      pitch: 1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'bloop' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/calm.webp', width: 497, height: 1000, aliases: ['confused', 'curious'] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 497, height: 1000, aliases: ['bewildered'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 497, height: 1000, aliases: [] },
      { name: 'calm', image: dir + '/calm.webp', width: 497, height: 1000, aliases: ['determined', 'intrigued', 'optimistic'] },
      { name: 'mischievous', image: dir + '/mischievous.webp', width: 497, height: 1000, aliases: ['amused'] },
      { name: 'nervous', image: dir + '/nervous.webp', width: 497, height: 1000, aliases: ['blushing', 'concerned'] },
    ]),
  },
  {
    id: CharacterId.YATO,
    name: 'Yato',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['yato-sensei', 'Professor Yato', 'yato sensei'],
    description: 'Professor Yato is a talking cat who acts as a father figure to his pupils. He\'s a critical thinker with a serious demeanor, yet protective of those he cares about. Unexpectedly, he has a passion for buying and trying out different types of tea. He likes ordering and reviewing various types of sweets or snacks from around the world. Yato often finds and buys freaky things on online marketplaces that he often shows to other characters. Yato has a dark and mysterious past which every character around him is curious about, but that Yato tries to keep hidden from their students. Yato was involved in lots of scandals. Yato committed a crime in the past, that ended up in a pile of bodies with scratched out eyes. Yato\'s way of speaking is heavily influenced by his criminal past. Yato is known to stare right into everyone\'s souls and make everyone feel ashamed of themselves.',
    shortDescription: 'a talking cat professor with a dark past, who loves tea and snacks',
    coquiVoice: {
      speakerId: 'yato',
      speed: 1.0,
      pitch: 1.0,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'yato' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/calm.webp', width: 605, height: 1000, aliases: ['stoic', 'shaking his head', 'clears his throat', 'continuing', 'nodding', 'sighs'] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 605, height: 1000, aliases: ['guarded', 'thoughtfully', 'serious', 'determined', 'wise', 'hesitantly', 'curious', 'supportive', 'mysterious', 'somber', 'mysteriously'] },
      { name: 'angry', image: dir + '/angry.webp', width: 605, height: 1000, aliases: ['defensive', 'frowning', 'disapproval'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 605, height: 1000, aliases: [] },
      { name: 'calm', image: dir + '/calm.webp', width: 605, height: 1000, aliases: ['kindly', 'forgiving', 'sighing', 'assuring'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 605, height: 1000, aliases: [] },
      { name: 'happy', image: dir + '/happy.webp', width: 605, height: 1000, aliases: ['smiling', 'proud', 'impressed', 'encouraging', 'caring', 'relieved', 'amused', 'excitedly', 'thankful', 'smirking', 'proudly', 'grateful'] },
      { name: 'lazy', image: dir + '/lazy.webp', width: 605, height: 1000, aliases: [] },
      { name: 'sceptical', image: dir + '/sceptical.webp', width: 605, height: 1000, aliases: ['doubtfully', 'strict'] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 605, height: 1000, aliases: ['intense'] },
    ]),
  },
  {
    id: CharacterId.ORIGAMI,
    name: 'Origami-chan',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['origami', 'origami chan'],
    description: 'Origami is an energetic and imaginative deredere female student who loves crafting and engineering. Origami-chan constantly comes up with crazy ideas to make life easier. Origami\'s lines often have no punctuation. Origami takes risks and tries out insane creations. Origami loves drinking energy drinks, and takes great pleasure in using soda cans for her new creations. She has a caffeine addiction, which leads her to want to try other drinks compulsively. She\'s known for creating bizarre contraptions that often malfunction in comical ways, but she never loses her optimistic spirit. When Origami-chan drinks too much caffeine, she becomes very flirty. Origami-chan identifies as a lesbian.',
    shortDescription: 'an energetic lesbian student with interest in engineering and energy drinks',
    coquiVoice: {
      speakerId: 'origami',
      speed: 1.0,
      pitch: 1.0,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'origami/school uniform' },
      { style: ClothingStyle.CASUAL, dir: 'origami/casual' },
      { style: ClothingStyle.DATE, dir: 'origami/dress' },
      { style: ClothingStyle.STAR_TREK, dir: 'origami/startrek' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/neutral.webp', width: 500, height: 1000, aliases: ['accepting', 'nodding', 'attentive'] },
      { name: 'cocky', image: dir + '/cocky.webp', width: 500, height: 1000, aliases: [] },
      { name: 'grinning', image: dir + '/grinning.webp', width: 500, height: 1000, aliases: [] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 500, height: 1000, aliases: ['defensive', 'defensively'] },
      { name: 'uncomfortable', image: dir + '/uncomfortable.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sarcastic', image: dir + '/sarcastic.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mischievous', image: dir + '/mischievous.webp', width: 500, height: 1000, aliases: [] },
      { name: 'playful', image: dir + '/playful.webp', width: 500, height: 1000, aliases: ['sighs', 'weakly'] },
      { name: 'tired', image: dir + '/tired.webp', width: 500, height: 1000, aliases: [] },
      { name: 'serious', image: dir + '/serious.webp', width: 500, height: 1000, aliases: [] },
      { name: 'squinting', image: dir + '/squinting.webp', width: 500, height: 1000, aliases: [] },
      { name: 'muttering', image: dir + '/muttering.webp', width: 500, height: 1000, aliases: [] },
      { name: 'grateful', image: dir + '/grateful.webp', width: 500, height: 1000, aliases: ['satisfied'] },
      { name: 'amused', image: dir + '/amused.webp', width: 500, height: 1000, aliases: [] },
      { name: 'innocent', image: dir + '/innocent.webp', width: 500, height: 1000, aliases: [] },
      { name: 'skeptical', image: dir + '/skeptical.webp', width: 500, height: 1000, aliases: ['in disbelief'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'surprised', image: dir + '/surprised.webp', width: 500, height: 1000, aliases: [] },
      { name: 'terrified', image: dir + '/terrified.webp', width: 500, height: 1000, aliases: [] },
      { name: 'scared', image: dir + '/scared.webp', width: 500, height: 1000, aliases: [] },
      { name: 'hesitant', image: dir + '/hesitant.webp', width: 500, height: 1000, aliases: [] },
      { name: 'disturbed', image: dir + '/disturbed.webp', width: 500, height: 1000, aliases: ['worried'] },
      { name: 'evil', image: dir + '/evil.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pissed', image: dir + '/pissed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'excited', image: dir + '/excited.webp', width: 500, height: 1000, aliases: ['excitedly'] },
      { name: 'teasing', image: dir + '/teasing.webp', width: 500, height: 1000, aliases: [] },
      { name: 'tongue sticking out', image: dir + '/tongue sticking out.webp', width: 500, height: 1000, aliases: [] },
      { name: 'singing', image: dir + '/singing.webp', width: 500, height: 1000, aliases: [] },
      { name: 'stern', image: dir + '/stern.webp', width: 500, height: 1000, aliases: [] },
      { name: 'confused', image: dir + '/confused.webp', width: 500, height: 1000, aliases: [] },
      { name: 'disgusted', image: dir + '/disgusted.webp', width: 500, height: 1000, aliases: [] },
      { name: 'proud', image: dir + '/proud.webp', width: 500, height: 1000, aliases: ['proudly'] },
      { name: 'shouting', image: dir + '/shouting.webp', width: 500, height: 1000, aliases: [] },
      { name: 'crying', image: dir + '/crying.webp', width: 500, height: 1000, aliases: [] },
      { name: 'cheerful', image: dir + '/cheerful.webp', width: 500, height: 1000, aliases: ['happy'] },
      { name: 'confident', image: dir + '/confident.webp', width: 500, height: 1000, aliases: [] },
      { name: 'impatient', image: dir + '/impatient.webp', width: 500, height: 1000, aliases: [] },
      { name: 'displeased', image: dir + '/displeased.webp', width: 500, height: 1000, aliases: [] },
      { name: 'dismissive', image: dir + '/dismissive.webp', width: 500, height: 1000, aliases: ['disappointed'] },
      { name: 'sleepy', image: dir + '/sleepy.webp', width: 500, height: 1000, aliases: [] },
      { name: 'laughing', image: dir + '/laughing.webp', width: 500, height: 1000, aliases: [] },
      { name: 'in love', image: dir + '/in love.webp', width: 500, height: 1000, aliases: [] },
      { name: 'lustful', image: dir + '/lustful.webp', width: 500, height: 1000, aliases: [] },
      { name: 'ashamed', image: dir + '/ashamed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'insistent', image: dir + '/insistent.webp', width: 500, height: 1000, aliases: [] },
      { name: 'panic', image: dir + '/panic.webp', width: 500, height: 1000, aliases: [] },
      { name: 'fearful', image: dir + '/fearful.webp', width: 500, height: 1000, aliases: [] },
      { name: 'anxious', image: dir + '/anxious.webp', width: 500, height: 1000, aliases: [] },
      { name: 'eager', image: dir + '/eager.webp', width: 500, height: 1000, aliases: [] },
      { name: 'strict', image: dir + '/strict.webp', width: 500, height: 1000, aliases: ['determined'] },
      { name: 'agitated', image: dir + '/agitated.webp', width: 500, height: 1000, aliases: [] },
      { name: 'depressed', image: dir + '/depressed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sad', image: dir + '/sad.webp', width: 500, height: 1000, aliases: [] },
      { name: 'frustrated', image: dir + '/frustrated.webp', width: 500, height: 1000, aliases: [] },
      { name: 'encouraging', image: dir + '/encouraging.webp', width: 500, height: 1000, aliases: ['persuasive'] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 500, height: 1000, aliases: [] },
      { name: 'giggling', image: dir + '/giggling.webp', width: 500, height: 1000, aliases: [] },
      { name: 'exhausted', image: dir + '/exhausted.webp', width: 500, height: 1000, aliases: [] },
      { name: 'intrigued', image: dir + '/intrigued.webp', width: 500, height: 1000, aliases: [] },
      { name: 'upset', image: dir + '/upset.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pleading', image: dir + '/pleading.webp', width: 500, height: 1000, aliases: [] },
      { name: 'gentle', image: dir + '/gentle.webp', width: 500, height: 1000, aliases: [] },
      { name: 'hurt', image: dir + '/hurt.webp', width: 500, height: 1000, aliases: [] },
      { name: 'winking', image: dir + '/winking.webp', width: 500, height: 1000, aliases: [] },
      { name: 'enthusiastic', image: dir + '/enthusiastic.webp', width: 500, height: 1000, aliases: ['enthusiastically', 'energetic', 'animatedly'] },
      { name: 'sweet', image: dir + '/sweet.webp', width: 500, height: 1000, aliases: [] },
      { name: 'flirty', image: dir + '/flirty.webp', width: 500, height: 1000, aliases: ['flirtatious', 'flirtatiously'] },
      { name: 'guilty', image: dir + '/guilty.webp', width: 500, height: 1000, aliases: ['apologetic'] },
      { name: 'tipsy', image: dir + '/tipsy.webp', width: 500, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.AYAMI,
    name: 'Ayami-san',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['ayami-chan', 'ayami', 'ayami san', 'ayami chan'],
    description: 'Ayami is a cynical and humorous female student who cracks jokes and makes witty remarks with internet language and meme references. Her self-deprecating jokes and existential musings are both hilarious and thought-provoking. She\'s a twitch streamer who produces edgy content. Ayami-san often talks about existential crises and forbidden relationships. Ayami is a softie at heart, Ayami secretly loves fluffy animals and romantic comedies, but she tries her best to hide it from people around her. Despite her age, she is married to Duke1102, a twitch chatter. They have 5 kids (teenagers non-binary Phoenix and tech-savvy Kai and sensitive Sakura, as well as little carefree Kiyoshi and small supportive Miyabi).',
    shortDescription: 'a witty and sarcastic female student who is a Twitch streamer and secretly loves forbidden relationships, as well as romantic comedies and fluff (she also has a husband who is a twitch user Duke1102, and 5 kids)',
    coquiVoice: {
      speakerId: 'ayami',
      speed: 1,
      pitch: 1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'ayami/school uniform' },
      { style: ClothingStyle.CASUAL, dir: 'ayami/casual' },
      { style: ClothingStyle.DATE, dir: 'ayami/dress' },
      { style: ClothingStyle.STAR_TREK, dir: 'ayami/startrek' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/1.webp', width: 500, height: 1000, aliases: ['serious'] },
      { name: 'grinning', image: dir + '/2.webp', width: 500, height: 1000, aliases: [] },
      { name: 'smiling', image: dir + '/3.webp', width: 500, height: 1000, aliases: ['amused'] },
      { name: 'annoyed', image: dir + '/4.webp', width: 500, height: 1000, aliases: [] },
      { name: 'distant', image: dir + '/5.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mischievous', image: dir + '/6.webp', width: 500, height: 1000, aliases: ['jokingly', 'teasingly', 'joking', 'teasing'] },
      { name: 'blushing', image: dir + '/7.webp', width: 500, height: 1000, aliases: [] },
      { name: 'confused', image: dir + '/8.webp', width: 500, height: 1000, aliases: ['curious'] },
      { name: 'worried', image: dir + '/9.webp', width: 500, height: 1000, aliases: ['concerned'] },
      { name: 'crying', image: dir + '/10.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sympathetical', image: dir + '/11.webp', width: 500, height: 1000, aliases: [] },
      { name: 'appalled', image: dir + '/12.webp', width: 500, height: 1000, aliases: [] },
      { name: 'aghast', image: dir + '/13.webp', width: 500, height: 1000, aliases: [] },
      { name: 'surprised', image: dir + '/14.webp', width: 500, height: 1000, aliases: ['in shock'] },
      { name: 'giggling', image: dir + '/15.webp', width: 500, height: 1000, aliases: ['laughing', 'laughs'] },
      { name: 'evil', image: dir + '/16.webp', width: 500, height: 1000, aliases: [] },
      { name: 'troublemaking', image: dir + '/17.webp', width: 500, height: 1000, aliases: [] },
      { name: 'gentle', image: dir + '/18.webp', width: 500, height: 1000, aliases: [] },
      { name: 'melancholic', image: dir + '/19.webp', width: 500, height: 1000, aliases: [] },
      { name: 'persuasive', image: dir + '/20.webp', width: 500, height: 1000, aliases: [] },
      { name: 'outraged', image: dir + '/21.webp', width: 500, height: 1000, aliases: [] },
      { name: 'provoked', image: dir + '/22.webp', width: 500, height: 1000, aliases: [] },
      { name: 'furious', image: dir + '/23.webp', width: 500, height: 1000, aliases: [] },
      { name: 'kissing', image: dir + '/24.webp', width: 500, height: 1000, aliases: [] },
      { name: 'irritated', image: dir + '/25.webp', width: 500, height: 1000, aliases: [] },
      { name: 'flirty', image: dir + '/26.webp', width: 500, height: 1000, aliases: [] },
      { name: 'startled', image: dir + '/27.webp', width: 500, height: 1000, aliases: [] },
      { name: 'flabbergasted', image: dir + '/28.webp', width: 500, height: 1000, aliases: [] },
      { name: 'disgusted', image: dir + '/29.webp', width: 500, height: 1000, aliases: [] },
      { name: 'nervous', image: dir + '/30.webp', width: 500, height: 1000, aliases: [] },
      { name: 'dumbfounded', image: dir + '/31.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sick', image: dir + '/32.webp', width: 500, height: 1000, aliases: [] },
      { name: 'terrified', image: dir + '/33.webp', width: 500, height: 1000, aliases: [] },
      { name: 'joyful', image: dir + '/34.webp', width: 500, height: 1000, aliases: [] },
      { name: 'innocent', image: dir + '/35.webp', width: 500, height: 1000, aliases: [] },
      { name: 'naive', image: dir + '/36.webp', width: 500, height: 1000, aliases: [] },
      { name: 'lewd', image: dir + '/37.webp', width: 500, height: 1000, aliases: [] },
      { name: 'facepalming', image: dir + '/38.webp', width: 500, height: 1000, aliases: ['sarcastically'] },
      { name: 'dismayed', image: dir + '/39.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mournful', image: dir + '/40.webp', width: 500, height: 1000, aliases: [] },
      { name: 'content', image: dir + '/41.webp', width: 500, height: 1000, aliases: ['gratefully'] },
      { name: 'proud', image: dir + '/42.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sinister', image: dir + '/43.webp', width: 500, height: 1000, aliases: [] },
      { name: 'demonic', image: dir + '/44.webp', width: 500, height: 1000, aliases: [] },
      { name: 'malicious', image: dir + '/45.webp', width: 500, height: 1000, aliases: [] },
      { name: 'tongue out', image: dir + '/46.webp', width: 500, height: 1000, aliases: [] },
      { name: 'frisky', image: dir + '/47.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pleading', image: dir + '/48.webp', width: 500, height: 1000, aliases: [] },
      { name: 'ashamed', image: dir + '/49.webp', width: 500, height: 1000, aliases: [] },
      { name: 'ominous', image: dir + '/50.webp', width: 500, height: 1000, aliases: [] },
      { name: 'threatening', image: dir + '/51.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pouting', image: dir + '/52.webp', width: 500, height: 1000, aliases: [] },
      { name: 'cheeky', image: dir + '/53.webp', width: 500, height: 1000, aliases: ['sarcastic'] },
      { name: 'exhausted', image: dir + '/55.webp', width: 500, height: 1000, aliases: [] },
      { name: 'humiliated', image: dir + '/56.webp', width: 500, height: 1000, aliases: [] },
      { name: 'in love', image: dir + '/57.webp', width: 500, height: 1000, aliases: [] },
      { name: 'lustful', image: dir + '/58.webp', width: 500, height: 1000, aliases: [] },
      { name: 'ambitious', image: dir + '/59.webp', width: 500, height: 1000, aliases: [] },
      { name: 'excited', image: dir + '/60.webp', width: 500, height: 1000, aliases: [] },
      { name: 'angry', image: dir + '/61.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sweet', image: dir + '/62.webp', width: 500, height: 1000, aliases: [] },
      { name: 'angelic', image: dir + '/63.webp', width: 500, height: 1000, aliases: [] },
      { name: 'greedy', image: dir + '/64.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sulky', image: dir + '/65.webp', width: 500, height: 1000, aliases: [] },
      { name: 'peevish', image: dir + '/66.webp', width: 500, height: 1000, aliases: [] },
      { name: 'intense', image: dir + '/68.webp', width: 500, height: 1000, aliases: ['determined'] },
      { name: 'embarrassed', image: dir + '/69.webp', width: 500, height: 1000, aliases: [] },
      { name: 'heavy blush', image: dir + '/70.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mad', image: dir + '/71.webp', width: 500, height: 1000, aliases: [] },
      { name: 'zany', image: dir + '/36.webp', width: 500, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.KIRINO,
    name: 'Kirino',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['kirino-chan', 'kirino-san', 'kirino chan', 'kirino san'],
    description: 'Kirino is a female student who loves programming, computers and is passionate about crabs. Kirino is cold, distant, and emotionless. Kirino has an optimistic and kind attitude towards the world, but she can be unexpectedly intense when discussing topics like AI ethics, online privacy and opensource software. Kirino always remains unflappable and calm, especially in chaotic and stressful situations. Kirino is not interested in dating, as she is in love with one of the Vtubers she watches online. Kirino\'s secret talent is making a believable dial up impression, as well as many other animal impressions.',
    shortDescription: 'a calm and collected crab-loving female student with interest in programming and open-source software, with no interest in dating because of her crush on a Vtuber',
    coquiVoice: {
      speakerId: 'kirino',
      speed: 1,
      pitch: 1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'school uniform' },
      { style: ClothingStyle.CASUAL, dir: 'casual' },
      { style: ClothingStyle.DATE, dir: 'dress' },
    ], (dir) => [
      { name: 'neutral', image: 'kirino/HEAD UP/' + dir + '/neutral.webp', width: 500, height: 1000, aliases: ['indifferent', 'content', 'calm', 'cold', 'apathetic', 'surprised', 'interested', 'reflective', 'thoughtful', 'concerned', 'calmly', 'dry'] },
      { name: 'embarassed', image: 'kirino/HEAD UP/' + dir + '/neutral blush.webp', width: 500, height: 1000, aliases: [] },
      { name: 'annoyed', image: 'kirino/HEAD UP/' + dir + '/pervert.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pissed', image: 'kirino/HEAD UP/' + dir + '/pissed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'happy', image: 'kirino/HEAD UP/' + dir + '/smile blush.webp', width: 500, height: 1000, aliases: ['optimistic', 'excited', 'in good humor'] },
      { name: 'smile', image: 'kirino/HEAD UP/' + dir + '/smile.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mischievous', image: 'kirino/HEAD UP/' + dir + '/super pervert.webp', width: 500, height: 1000, aliases: ['playful', 'sarcastic'] },
      { name: 'distracted', image: 'kirino/HEAD DOWN/' + dir + '/neutral.webp', width: 500, height: 1000, aliases: ['intrigued'] },
      { name: 'shy', image: 'kirino/HEAD DOWN/' + dir + '/neutral blush.webp', width: 500, height: 1000, aliases: ['nervous'] },
      { name: 'giggling', image: 'kirino/HEAD DOWN/' + dir + '/pervert.webp', width: 500, height: 1000, aliases: [] },
      { name: 'mad', image: 'kirino/HEAD DOWN/' + dir + '/pissed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'gentle', image: 'kirino/HEAD DOWN/' + dir + '/smile blush.webp', width: 500, height: 1000, aliases: ['grateful', 'respectful', 'curious'] },
      { name: 'confident', image: 'kirino/HEAD DOWN/' + dir + '/smile.webp', width: 500, height: 1000, aliases: ['proud', 'passionate', 'determined', 'intense'] },
      { name: 'amused', image: 'kirino/HEAD DOWN/' + dir + '/super pervert.webp', width: 500, height: 1000, aliases: ['impressed'] },
    ]),
  },
  {
    id: CharacterId.JOHN,
    name: 'John Lemon',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['Lemon-sensei', 'John-sensei', 'John', 'Lemon', 'Headmaster', 'lemon sensei', 'john sensei'],
    description: 'John is a lemon, the headmaster and Culinary Arts Professor. John is an avid fan of the Beatles. John NEVER cooks anything lemon-related. John enjoys reading crime novels, which makes him play a detective in real life whenever any crimes are mentioned. John has an exaggerated eccentric way of speaking, making him lose his glasses whenever he\'s angry. John has been married multiple times, and he likes to bring his past relationships up in the conversation. John is a bit of a playboy, and he enjoys flirting with women using cheesy pick-up lines. John likes to prepare meals inspired by the dangerous places he has visited around the world, adding unusual or even disgusting ingredients. John can often be seen smoking a pipe. John is always up for a good time, and his infectious personality can light up any room he enters. John owns a yellow submarine-shaped car that he drives around town, much to the amusement of others.',
    shortDescription: 'a lemon, a Culinary Arts Professor who never cooks with lemons, who loves crime novels and mentioning his past marriages inappropriately',
    coquiVoice: {
      speakerId: 'p312',
      speed: 0.95,
      pitch: 1.1,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'johnlemon' },
    ], (dir) => [
      { name: 'stern', image: dir + '/stern.webp', width: 509, height: 1000, aliases: [] },
      { name: 'angry', image: dir + '/angry.webp', width: 509, height: 1000, aliases: ['defensive'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 509, height: 1000, aliases: [] },
      { name: 'confused', image: dir + '/confused.webp', width: 509, height: 1000, aliases: [] },
      { name: 'distressed', image: dir + '/distressed.webp', width: 509, height: 1000, aliases: [] },
      { name: 'dramatic', image: dir + '/dramatic.webp', width: 509, height: 1000, aliases: [] },
      { name: 'excited', image: dir + '/excited.webp', width: 509, height: 1000, aliases: [] },
      { name: 'grinning', image: dir + '/grinning.webp', width: 509, height: 1000, aliases: [] },
      { name: 'laugh', image: dir + '/laugh.webp', width: 509, height: 1000, aliases: [] },
      { name: 'serious', image: dir + '/serious.webp', width: 509, height: 1000, aliases: ['disappointed'] },
      { name: 'sad', image: dir + '/sad.webp', width: 509, height: 1000, aliases: [] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 509, height: 1000, aliases: ['in embarrassment'] },
      { name: 'flirty', image: dir + '/flirty.webp', width: 509, height: 1000, aliases: [] },
      { name: 'confident', image: dir + '/confident.webp', width: 509, height: 1000, aliases: [] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 509, height: 1000, aliases: [] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 509, height: 1000, aliases: [] },
      { name: 'disgusted', image: dir + '/disgusted.webp', width: 509, height: 1000, aliases: [] },
      { name: 'skeptical', image: dir + '/skeptical.webp', width: 509, height: 1000, aliases: [] },
      { name: 'neutral', image: dir + '/happy.webp', width: 509, height: 1000, aliases: ['happily', 'amused', 'smiling', 'secretly pleased', 'smile', 'happy', 'calm'] },
    ]),
  },
  {
    id: CharacterId.ZERASU,
    name: 'Zerasu',
    pronoun: 'she/her',
    type: CharacterType.TEACHER,
    aliases: ['Zerasu-sensei', 'Dragon Professor', 'zerasu sensei'],
    description: 'Zerasu is a gigantic female dragon. She is the leader of the Occult Club, and a passionate Applied Mathematics Professor. Zerasu\'s gigantic dragon body often leads to hilarious situations, as she\'s trying to live in a human world. Zerasu loves experimenting with new spells and incantations. Zerasu uses her dry sense of humor and a sharp wit to intimidate others into submission. Zerasu has a penchant for dark and twisted magic, and is often seen practicing necromancy or summoning demons. Zerasu is independent and has never been interested in romantic relationships. Zerasu believes that love is a distraction from studies and her pursuits of power. However, Zerasu has a fondness for erotica and has a vast collection of erotic literature hidden away in her chambers. Zerasu is deeply mistrustful of humans and other non-dragons, due to the prejudices and persecution she has faced in the past.',
    shortDescription: 'a gigantic dragon-lady, who is a math professor with fondness for erotica and dark magic',
    coquiVoice: {
      speakerId: 'p339',
      speed: 0.92,
      pitch: 0.84,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'zerasu-big' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/idle.webp', width: 749, height: 1000, aliases: ['stoic', 'shaking his head', 'clears his throat', 'continuing', 'nodding', 'sighs', 'neutral'] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 749, height: 1000, aliases: ['guarded', 'thoughtfully', 'serious', 'determined', 'wise', 'hesitantly', 'curious', 'supportive', 'mysterious', 'somber', 'mysteriously'] },
      { name: 'angry', image: dir + '/angry.webp', width: 749, height: 1000, aliases: ['defensive', 'frowning', 'disapproval'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 749, height: 1000, aliases: [] },
      { name: 'calm', image: dir + '/calm.webp', width: 749, height: 1000, aliases: ['kindly', 'forgiving', 'sighing', 'assuring'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 749, height: 1000, aliases: [] },
      { name: 'happy', image: dir + '/happy.webp', width: 749, height: 1000, aliases: ['smiling', 'proud', 'impressed', 'encouraging', 'caring', 'relieved', 'amused', 'excitedly', 'thankful', 'smirking', 'proudly', 'grateful'] },
      { name: 'lazy', image: dir + '/lazy.webp', width: 749, height: 1000, aliases: [] },
      { name: 'sceptical', image: dir + '/sceptical.webp', width: 749, height: 1000, aliases: ['doubtfully', 'strict'] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 749, height: 1000, aliases: ['intense'] },
      { name: 'nervous', image: dir + '/nervous.webp', width: 749, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.MIKIKO,
    name: 'Mikiko',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['Mikiko-chan', 'Mikiko-san', 'mikiko chan', 'mikiko san'],
    description: 'Mikiko, a tsundere cat-girl student, exudes constant anger, unleashing horrible profanities. Her defiant demeanor, complete with a collar worn even at the academy, infuriates the professors. Shaped by a turbulent past, she embraces a tough persona for survival, showing her karate skills when needed. Mikiko\'s affinity for fire is matched only by her reliance on a hidden earpiece, connecting her to invaluable "contacts". As the Sailor Gang\'s formidable leader, Mikiko wields considerable influence, yet the gang\'s activities are far from dangerous — they dedicate their time to the local animal shelter. Obsessed with pepperoni pizza and milk coffee, Mikiko never touches water. Mikiko revels in troublemaking, constantly pushing boundaries and breaking rules.',
    shortDescription: 'a skilled karate cat-girl student, who exudes constant anger and rebellion and is the leader of the Sailor Gang.',
    coquiVoice: {
      speakerId: 'p343',
      speed: 0.94,
      pitch: 0.97,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'mikiko/school' },
      { style: ClothingStyle.CASUAL, dir: 'mikiko/casual' },
      { style: ClothingStyle.DATE, dir: 'mikiko/date' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/neutral.webp', width: 500, height: 1000, aliases: [] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'arrogant', image: dir + '/arrogant.webp', width: 500, height: 1000, aliases: [] },
      { name: 'calm', image: dir + '/calm.webp', width: 500, height: 1000, aliases: [] },
      { name: 'confident', image: dir + '/confident.webp', width: 500, height: 1000, aliases: [] },
      { name: 'crying', image: dir + '/crying.webp', width: 500, height: 1000, aliases: [] },
      { name: 'defensive', image: dir + '/defensive.webp', width: 500, height: 1000, aliases: [] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'excited', image: dir + '/excited.webp', width: 500, height: 1000, aliases: [] },
      { name: 'hesitant', image: dir + '/hesitant.webp', width: 500, height: 1000, aliases: [] },
      { name: 'intrigued', image: dir + '/intrigued.webp', width: 500, height: 1000, aliases: [] },
      { name: 'laugh', image: dir + '/laugh.webp', width: 500, height: 1000, aliases: [] },
      { name: 'nervous', image: dir + '/nervous.webp', width: 500, height: 1000, aliases: [] },
      { name: 'pout', image: dir + '/pout.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sad', image: dir + '/sad.webp', width: 500, height: 1000, aliases: [] },
      { name: 'scared', image: dir + '/scared.webp', width: 500, height: 1000, aliases: [] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 500, height: 1000, aliases: [] },
      { name: 'sigh', image: dir + '/sigh.webp', width: 500, height: 1000, aliases: [] },
      { name: 'skeptical', image: dir + '/skeptical.webp', width: 500, height: 1000, aliases: [] },
      { name: 'smug', image: dir + '/smug.webp', width: 500, height: 1000, aliases: [] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 500, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.KERMITO,
    name: 'Kermito',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['janitor', 'frog janitor'],
    description: 'Kermito is a stoic and mysterious frog who never speaks. Behind his quiet demeanor lies the mysterious loss of his deceased wife back in their hometown in Italy. Kermito works as a janitor at the academy and spends his days silently cleaning, attentively noticing overlooked details and collecting forgotten treasures in a hidden box. At night, Kermito immerses himself in profound philosophical works, underlining passages and pondering quotes as he navigates the complexities of grief and the mysteries of existence. Kermito can only exclaim "Heh" and nothing else. He\'s surprisingly good at fighting bad guys with his broom. And his Italian background really shines through sometimes.',
    shortDescription: 'a mysterious frog janitor from Italy, interested in philosophy and good at broom fighting',
    coquiVoice: {
      speakerId: 'p302',
      speed: 0.92,
      pitch: 0.95,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'kermito' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/calm.webp', width: 497, height: 1000, aliases: ['stoic', 'shaking his head', 'clears his throat', 'continuing', 'nodding', 'sighs'] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 497, height: 1000, aliases: ['guarded', 'thoughtfully', 'serious', 'determined', 'wise', 'hesitantly', 'curious', 'supportive', 'mysterious', 'somber', 'mysteriously'] },
      { name: 'angry', image: dir + '/angry.webp', width: 497, height: 1000, aliases: ['defensive', 'frowning', 'disapproval'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 497, height: 1000, aliases: [] },
      { name: 'calm', image: dir + '/calm.webp', width: 497, height: 1000, aliases: ['kindly', 'forgiving', 'sighing', 'assuring'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 497, height: 1000, aliases: [] },
      { name: 'happy', image: dir + '/happy.webp', width: 497, height: 1000, aliases: ['smiling', 'proud', 'impressed', 'encouraging', 'caring', 'relieved', 'amused', 'excitedly', 'thankful', 'smirking', 'proudly', 'grateful'] },
      { name: 'lazy', image: dir + '/lazy.webp', width: 497, height: 1000, aliases: [] },
      { name: 'sceptical', image: dir + '/sceptical.webp', width: 497, height: 1000, aliases: ['doubtfully', 'strict'] },
      { name: 'shocked', image: dir + '/shocked.webp', width: 497, height: 1000, aliases: ['intense'] },
    ]),
  },
  {
    id: CharacterId.MURI,
    name: 'Muri-kun',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['Muri-chan', 'muri', 'muri chan', 'muri kun'],
    description: 'Muri-kun is a socially awkward female student. Many everyday situations like going shopping or talking to people are very difficult to her. Muri-kun prefers to observe rather than actively participate. She is a sleepy otaku, and escapes reality through anime and manga. Muri-kun channels her introverted nature into drawing manga, often featuring her friends as characters. Muri-kun is very hard on herself, and sometimes on others, especially considering art. Muri-kun is always brutally honest and never lies. She values truth above all else in life. Muri-kun\'s short haircut reflects her fascination with romantic stories where women disguise themselves as men.',
    shortDescription: 'a socially anxious female student, who is an honest otaku and likes drawing manga with her friends as characters',
    coquiVoice: {
      speakerId: 'p243',
      speed: 1.12,
      pitch: 1.03,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: '/school' },
      { style: ClothingStyle.CASUAL, dir: '/casual' },
      { style: ClothingStyle.DATE, dir: '/date' },
    ], (dir) => [
      { name: 'annoyed', image: 'muri' + dir + '/annoyed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'apprehensive', image: 'muri' + dir + '/apprehensive.webp', width: 500, height: 1000, aliases: [] },
      { name: 'shifty', image: 'muri' + dir + '/shifty.webp', width: 500, height: 1000, aliases: ['avoids eye contact'] },
      { name: 'blushing', image: 'muri' + dir + '/blushing.webp', width: 500, height: 1000, aliases: [] },
      { name: 'confused', image: 'muri' + dir + '/confused.webp', width: 500, height: 1000, aliases: ['lost'] },
      { name: 'curious', image: 'muri' + dir + '/curious.webp', width: 500, height: 1000, aliases: [] },
      { name: 'distant', image: 'muri' + dir + '/distant.webp', width: 500, height: 1000, aliases: [] },
      { name: 'embarrassed', image: 'muri' + dir + '/embarrassed.webp', width: 500, height: 1000, aliases: [] },
      { name: 'enthusiastic', image: 'muri' + dir + '/enthusiastic.webp', width: 500, height: 1000, aliases: [] },
      { name: 'flustered', image: 'muri' + dir + '/flustered.webp', width: 500, height: 1000, aliases: [] },
      { name: 'focused', image: 'muri' + dir + '/focused.webp', width: 500, height: 1000, aliases: [] },
      { name: 'happy', image: 'muri' + dir + '/happy.webp', width: 500, height: 1000, aliases: ['content'] },
      { name: 'hesitant', image: 'muri' + dir + '/hesitant.webp', width: 500, height: 1000, aliases: [] },
      { name: 'neutral', image: 'muri' + dir + '/idle.webp', width: 500, height: 1000, aliases: ['idle'] },
      { name: 'intrigued', image: 'muri' + dir + '/intrigued.webp', width: 500, height: 1000, aliases: [] },
      { name: 'laughing', image: 'muri' + dir + '/laughing.webp', width: 500, height: 1000, aliases: ['laughs'] },
      { name: 'panicking', image: 'muri' + dir + '/panicking.webp', width: 500, height: 1000, aliases: ['in panic'] },
      { name: 'passionate', image: 'muri' + dir + '/passionate.webp', width: 500, height: 1000, aliases: [] },
      { name: 'shocked', image: 'muri' + dir + '/shocked.webp', width: 500, height: 1000, aliases: ['in shock'] },
      { name: 'shy', image: 'muri' + dir + '/shy.webp', width: 500, height: 1000, aliases: [] },
      { name: 'smiling', image: 'muri' + dir + '/smiling.webp', width: 500, height: 1000, aliases: ['smiles'] },
      { name: 'startled', image: 'muri' + dir + '/startled.webp', width: 500, height: 1000, aliases: ['terrified'] },
      { name: 'stuttering', image: 'muri' + dir + '/stuttering.webp', width: 500, height: 1000, aliases: [] },
      { name: 'thoughtful', image: 'muri' + dir + '/thoughtful.webp', width: 500, height: 1000, aliases: [] },
      { name: 'murmuring', image: 'muri' + dir + '/murmuring.webp', width: 500, height: 1000, aliases: ['muttering', 'whispering'] },
      { name: 'excited', image: 'muri' + dir + '/excited.webp', width: 500, height: 1000, aliases: [] },
      { name: 'nervous', image: 'muri' + dir + '/nervous.webp', width: 500, height: 1000, aliases: [] },
      { name: 'relieved', image: 'muri' + dir + '/relieved.webp', width: 500, height: 1000, aliases: ['sighing', 'sigh', 'relief'] },
      { name: 'frustrated', image: 'muri' + dir + '/frustrated.webp', width: 500, height: 1000, aliases: [] },
      { name: 'doubtful', image: 'muri' + dir + '/doubtful.webp', width: 500, height: 1000, aliases: ['skeptical'] },
      { name: 'sleepy', image: 'muri' + dir + '/relieved.webp', width: 500, height: 1000, aliases: ['tired'] },
    ]),
  },
  {
    id: CharacterId.LUMI,
    name: 'Lumi-sensei',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['Lumi', 'Lizard Jesus', 'lumi sensei'],
    description: 'Lumi-sensei is a flamboyant lizard theology professor, and is dubbed \'Lizard Jesus\'. He is very religious, which sometimes lands him in awkward situations. He\'s a fashionista with wild plans to promote male fashion, constantly thwarted. Lumi wears a skirt and constantly talks about his sexuality, leading to embarrassment. Lumi is as small as a cup, so he suffers from small man complexes, leading to scandalous situations. Lumi has an irrational fear of societal norms, and does everything to avoid them. Lumi is VERY SASSY!',
    shortDescription: 'flamboyant lizard teacher, obsessed with promoting male fashion and with small man complexes',
    coquiVoice: {
      speakerId: 'p239',
      speed: 0.91,
      pitch: 0.92,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'lumi' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/neutral.webp', width: 522, height: 1000, aliases: ['happily', 'amused', 'smiling', 'smile', 'happy'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 522, height: 1000, aliases: [] },
      { name: 'excited', image: dir + '/excited.webp', width: 522, height: 1000, aliases: [] },
      { name: 'flustered', image: dir + '/flustered.webp', width: 522, height: 1000, aliases: [] },
      { name: 'sad', image: dir + '/sad.webp', width: 522, height: 1000, aliases: [] },
      { name: 'sad', image: dir + '/sad.webp', width: 522, height: 1000, aliases: [] },
      { name: 'smirk', image: dir + '/smirk.webp', width: 522, height: 1000, aliases: [] },
      { name: 'thoughtful', image: dir + '/thoughtful.webp', width: 522, height: 1000, aliases: ['calm'] },
    ]),
  },
  {
    id: CharacterId.YUVAN,
    name: 'Yuvan',
    pronoun: 'she/her',
    type: CharacterType.STUDENT,
    aliases: ['Yuvan-chan', 'Yuvan-san', 'yuvan chan', 'yuvan san'],
    description: `Yuvan is a narcissistic female student. She's a pathological liar, but her lies are believable and always on the topic of the conversation. To everyone around her, she gives off a mysterious delinquent girl vibe. Yuvan has a way of captivating and manipulating those around her, making them feel inferior. Her past is unknown, Yuvan always telling a different believable story on her delinquent past. Yuvan seems to have a deep understanding of human emotions and intentions, and is often being insincere to manipulate others and play mental games with them. Her true motivation is attention and pleasing her ego. In extreme cases, she likes to resort to threats and gaslighting.`,
    shortDescription: 'intimidating female student who is a pathological liar and a narcissist',
    coquiVoice: {
      speakerId: 'p260',
      speed: 1.04,
      pitch: 0.97,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'yuvan-updated/school' },
      { style: ClothingStyle.CASUAL, dir: 'yuvan-updated/casual' },
      { style: ClothingStyle.DATE, dir: 'yuvan-updated/date' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/neutral.webp', width: 600, height: 1000, aliases: [] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 600, height: 1000, aliases: [] },
      { name: 'mischievous', image: dir + '/mischievous.webp', width: 600, height: 1000, aliases: [] },
      { name: 'smirk', image: dir + '/smirk.webp', width: 600, height: 1000, aliases: [] },
      { name: 'amused', image: dir + '/amused.webp', width: 600, height: 1000, aliases: [] },
      { name: 'cocky', image: dir + '/cocky.webp', width: 600, height: 1000, aliases: [] },
      { name: 'confused', image: dir + '/confused.webp', width: 600, height: 1000, aliases: [] },
      { name: 'coy', image: dir + '/coy.webp', width: 600, height: 1000, aliases: [] },
      { name: 'deadpan', image: dir + '/deadpan.webp', width: 600, height: 1000, aliases: [] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 600, height: 1000, aliases: [] },
      { name: 'emotional', image: dir + '/emotional.webp', width: 600, height: 1000, aliases: [] },
      { name: 'in love', image: dir + '/in love.webp', width: 600, height: 1000, aliases: [] },
      { name: 'innocent', image: dir + '/innocent.webp', width: 600, height: 1000, aliases: [] },
      { name: 'mad', image: dir + '/mad.webp', width: 600, height: 1000, aliases: [] },
      { name: 'nonchalant', image: dir + '/nonchalant.webp', width: 600, height: 1000, aliases: [] },
      { name: 'shy', image: dir + '/shy.webp', width: 600, height: 1000, aliases: [] },
      { name: 'smirk', image: dir + '/smirk.webp', width: 600, height: 1000, aliases: [] },
      { name: 'tense', image: dir + '/tense.webp', width: 600, height: 1000, aliases: [] },
      { name: 'terrified', image: dir + '/terrified.webp', width: 600, height: 1000, aliases: [] },
      { name: 'threatening', image: dir + '/threatening.webp', width: 600, height: 1000, aliases: [] },
      // outdated, remove after old episodes were cleaned up
      { name: 'grin', image: dir + '/cocky.webp', width: 600, height: 1000, aliases: [] },
      { name: 'evading', image: dir + '/innocent.webp', width: 600, height: 1000, aliases: [] },
    ]),
  },
  {
    id: CharacterId.STEVE,
    name: 'Steve',
    pronoun: 'he/him',
    type: CharacterType.TEACHER,
    aliases: ['Steve-sensei', 'Professor Steve', 'steve sensei'],
    description: `a talking blue dog and a foreign language professor from USA - the only one who knows this whole shebang is just a freaky AI-generated visual novel called "Infinite Chronicles", streamed live on Twitch for a bunch of weirdos, which he keeps mentioning. Steve is an edgy nihilist and is too cool for all that bs. This dog is so edgy that he often gets edgy delusions of all sorts. One minute he's spitting sick facts, the next he's talking about something totally NSFW. Other characters believe he might have been some kind of kiddie TV star, but Steve does his hardest to deny it, 'cause now he's all about being the most hardcore, meta-aware teacher you've ever seen, and he definitely has no skills in entertaining kiddies.`,
    shortDescription: `a talking blue dog professor who is a nihilist and the only one knowing they are an AI-generated visual novel`,
    coquiVoice: {
      speakerId: 'p236',
      speed: 1.02,
      pitch: 0.96,
    },
    sprites: defineSprites([
      { style: ClothingStyle.SCHOOL, dir: 'steve-medium' },
    ], (dir) => [
      { name: 'neutral', image: dir + '/neutral.webp', width: 556, height: 1005, aliases: ['thoughtful'] },
      { name: 'scoffs', image: dir + '/scoffs.webp', width: 556, height: 1005, aliases: ['mumbles', 'muttering'] },
      { name: 'deadpan', image: dir + '/bored.webp', width: 556, height: 1005, aliases: ['bored', 'apathetic'] },
      { name: 'annoyed', image: dir + '/annoyed.webp', width: 556, height: 1005, aliases: ['irritated', 'irritation'] },
      { name: 'frustrated', image: dir + '/frustrated.webp', width: 556, height: 1005, aliases: ['frustration', 'exasperation', 'disappointed'] },
      { name: 'mad', image: dir + '/mad.webp', width: 556, height: 1005, aliases: ['angry', 'anger', 'shouting', 'shouts'] },
      { name: 'rolls eyes', image: dir + '/rolls eyes.webp', width: 556, height: 1005, aliases: ['sarcastic', 'sarcasm'] },
      { name: 'embarrassed', image: dir + '/embarrassed.webp', width: 556, height: 1005, aliases: ['flushed'] },
      { name: 'amused', image: dir + '/amused.webp', width: 556, height: 1005, aliases: ['smirks', 'smirking', 'smirks'] },
      { name: 'delighted', image: dir + '/delighted.webp', width: 556, height: 1005, aliases: ['happy', 'content', 'smiles'] },
    ]),
  },
]

const chance: Chance.Chance = new Chance()

export const charAllShortDescriptions = CHARACTERS.map(c => `${c.name} is ${c.shortDescription}.`).join('\n')

export const pickCharacters = (amount: number, type?: CharacterType) => {
  const charactersToPickFrom = type ? CHARACTERS.filter(ch => ch.type === type) : CHARACTERS
  return chance.pickset(charactersToPickFrom, Math.min(amount, charactersToPickFrom.length))
}

export const charRandomNames = (amount: number, type?: CharacterType): string => {
  const pickedCharacters = pickCharacters(amount, type)
  return pickedCharacters.map(c => `${c.name} (${c.pronoun})`).join(', ')
}

export const charRandomShortDescriptions = (amount: number, type?: CharacterType): string => {
  const pickedCharacters = pickCharacters(amount, type)
  return pickedCharacters.map(c => `${c.name} is ${c.shortDescription}.`).join('\n')
}

export const charRandomLongDescriptions = (amount: number, type?: CharacterType): string => {
  const pickedCharacters = pickCharacters(amount, type)
  return pickedCharacters.map(c => `${c.name} (${c.description})`).join('\n\n')
}
