<template>
  <div v-if="settings">
    <v-checkbox
      v-model="settings.create_episodes"
      label="Enable episode creation"
    />
    <v-text-field
      v-model="settings.create_episodes_count"
      type="number"
      label="How many episodes to create"
    />
    <v-checkbox
      v-model="settings.cleanup_episodes"
      label="Enable cleanup episodes"
    />
    <v-text-field
      v-model="settings.cleanup_episodes_keep"
      type="number"
      label="How many episodes to keep during cleanup"
    />
    <v-checkbox
      v-model="settings.cleanup_polls"
      label="Enable cleanup polls"
    />
    <v-text-field
      v-model="settings.cleanup_polls_keep"
      type="number"
      label="How many polls to keep during cleanup"
    />
    <v-checkbox
      v-model="settings.automatic_polls_enabled"
      label="Enable automatic polls"
    />
    <v-text-field
      v-model="settings.poll_wait_minutes"
      type="number"
      label="How many minutes to wait before new poll"
    />
    <v-text-field
      v-model="settings.poll_min_episodes"
      type="number"
      label="How many episodes should play between each poll"
    />
    <v-checkbox
      v-model="settings.poll_comment_messages_enabled"
      label="Enable commenting on twitch messages during poll"
    />
    <v-text-field
      v-model="settings.create_comments_count"
      type="number"
      label="How many lines of twitch comments to request"
    />
    <v-text-field
      v-model="settings.moderation_max_value"
      type="number"
      step="0.1"
      max="1"
      label="Maximum moderation value (from 0 to 1)"
    />
    <v-select
      v-model="settings.default_ai"
      :items="aiTypes"
      label="Default AI"
    />
    <v-btn
      block
      color="green-darken-3"
      @click="saveSettings"
    >
      Save
    </v-btn>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { toast } from '../../toast'
import { AiType, Settings } from '../../../../common/src/Types'

const aiTypes = Object.values(AiType)

const settings = ref<Settings | null>(null)

const saveSettings = async () => {
  const res = await fetch(`/api/settings`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(settings.value),
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      settings.value = data
      toast('Settings saved', 'success')
    } catch (e) {
      toast('Unable to save settings', 'error')
    }
  } else {
    toast('Unable to save settings', 'error')
  }
}

const loadSettings = async () => {
  const res = await fetch(`/api/settings`, {
    credentials: 'include',
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      settings.value = data
    } catch (e) {
      toast('Unable to load settings', 'error')
    }
  } else {
    toast('Unable to load settings', 'error')
  }
}

onMounted(async () => {
  await loadSettings()
})
</script>
