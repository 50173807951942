<template>
  <InspirationPromptDialog
    v-model="openInspirationPrompt"
  />
  <div class="d-flex my-2">
    <div class="episodes-list">
      <v-btn
        v-if="!isGuest"
        color="warning"
        href="/control"
        class="mt-4 flex-fill"
        variant="tonal"
      >
        Go to control panel
      </v-btn>
      <v-btn
        color="primary"
        class="mt-4 flex-fill"
        variant="tonal"
        @click="reload"
      >
        🔄 Reload list
      </v-btn>
      <v-btn
        color="primary"
        class="ma-4 flex-fill"
        variant="tonal"
        @click="openInspirationPrompt = true"
      >
        🤔 Need some inspiration?
      </v-btn>
      <PaginationNav
        :pagination="p"
        @click="onPagination"
      />
      <TransitionGroup
        name="episodes-list-fade"
        tag="div"
        class="episodes-info-container-inner"
      >
        <div
          v-for="(ep) in episodes"
          :key="ep.id"
        >
          <EpisodeControlTableRow
            :episode="ep"
            @set-played="onSetPlayed"
            @add-to-playlist="onAddToPlaylist"
            @cancel-job="onCancelJob"
          />
        </div>
      </TransitionGroup>
      <PaginationNav
        :pagination="p"
        @click="onPagination"
      />
    </div>
    <div
      v-if="!isGuest"
      styles="flex: 1"
    >
      <v-card
        max-height="800px"
        class="overflow-auto mx-auto"
        max-width="500"
      >
        <v-banner>
          <v-banner-text>
            Playlist ({{ playlist.length }} in queue)
          </v-banner-text>
          <template #actions>
            <v-btn
              color="grey"
              @click="removeCount(10)"
            >
              -10
            </v-btn>
            <v-btn
              color="grey"
              @click="addGenerics(10)"
            >
              +10 🎭
            </v-btn>
            <v-btn
              color="primary"
              @click="addGenerics(100)"
            >
              +100 🎭
            </v-btn>
          </template>
          <br>
        </v-banner>
        <EpisodesPlaylist
          :items="playlist"
          @remove-from-playlist="onRemoveFromPlaylist"
          @playlist-order-change="onPlaylistOrderChange"
        />
      </v-card>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { PaginationDef } from '../../Types'
import { EpisodesRow } from '../../../../common/src/EpisodesRow'
import PaginationNav from './PaginationNav.vue'
import EpisodeControlTableRow from './EpisodeControlTableRow.vue'
import EpisodesPlaylist from './EpisodesPlaylist.vue'
import user from '../../user'
import { CommunicationWs } from '../../CommunicationWs'
import { EpisodesInfoData, WsPath } from '../../../../common/src/Types'
import InspirationPromptDialog from '../InspirationPromptDialog.vue'

const isGuest = computed(() => !user.getMe())
const comm = new CommunicationWs()
const episodes = ref<EpisodesRow[]>([])
const playlist = ref<EpisodesRow[]>([])
const p = ref<PaginationDef>({ offset: 0, limit: 5, total: 0 })

const openInspirationPrompt = ref<boolean>(false)

const addGenerics = async (count: number) => {
  const res = await fetch(`/api/playlist/_add_generic`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ count }),
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const removeCount = async (count: number) => {
  const res = await fetch(`/api/playlist/_remove`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ count }),
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const onPagination = async (args: { limit: number, offset: number }) => {
  p.value.limit = args.limit
  p.value.offset = args.offset

  comm.requestEpisodesLimit = p.value.limit
  comm.requestEpisodesOffset = p.value.offset
    comm.requestEpisodes()
}

const onSetPlayed = async (ep: EpisodesRow) => {
  const res = await fetch(`/api/episodes/${ep.id}/_setplayed`, {
    method: 'POST',
    credentials: 'include',
  })
  const episode: EpisodesRow | null = await res.json()
  if (episode) {
    episodes.value = episodes.value.map(e => {
      if (e.id === episode.id) {
        return episode
      }
      return e
    })
  }
}

const onAddToPlaylist = async (ep: EpisodesRow) => {
  const res = await fetch(`/api/episodes/${ep.id}/_add_to_playlist`, {
    method: 'POST',
    credentials: 'include',
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const onCancelJob = async (ep: EpisodesRow) => {
  const res = await fetch(`/api/episodes/${ep.id}/_stop_generation`, {
    method: 'POST',
    credentials: 'include',
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const onRemoveFromPlaylist = async (ep: EpisodesRow) => {
  const res = await fetch(`/api/episodes/${ep.id}/_remove_from_playlist`, {
    method: 'POST',
    credentials: 'include',
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const onPlaylistOrderChange = async (oldIndex: number, newIndex: number) => {
  const res = await fetch(`/api/playlist/_move`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: playlist.value[oldIndex].id,
      index: newIndex,
    }),
  })
  if (res.status === 200) {
    comm.requestEpisodes()
  }
}

const onEpisodesInfo = (info: EpisodesInfoData) => {
  episodes.value = info.listedEpisodes
  p.value.total = info.totalEpisodesCount
  playlist.value = info.playlistEpisodes
}

const reload = (async () => {
  comm.stop()
  await comm.start(WsPath.EPISODES)
  comm.requestEpisodes()
}) 

onMounted(async () => {
  comm.onEpisodesInfo(onEpisodesInfo)
  comm.requestEpisodesLimit = p.value.limit
  comm.requestEpisodesOffset = p.value.offset
  await comm.start(WsPath.EPISODES)
  comm.requestEpisodes()
})

onBeforeUnmount(() => {
  comm.stop()
})
</script>

<style
lang="scss"
scoped
>
.episodes-list{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 24px;
  width: 600px;
}</style>
