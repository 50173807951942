export interface Holiday {
  start: Date
  end: Date
  prompt: string
}

export const holidayDays: Array<Holiday> = [
    {
      start: new Date(new Date().getFullYear(), 11, 24),
      end: new Date(new Date().getFullYear(), 0, 1),
      prompt: "This chapter takes place during winter, Christmas or 2023 New Year's eve time.",
    },
    {
      start: new Date(new Date().getFullYear(), 1, 14),
      end: new Date(new Date().getFullYear(), 1, 14),
      prompt: "This chapter takes place during Valentine's Day.",
    },
    {
      start: new Date(new Date().getFullYear(), 10, 31),
      end: new Date(new Date().getFullYear(), 10, 31),
      prompt: 'This chapter takes place during Halloween.',
    },
    // Easter (dynamic date)
    {
      start: new Date(getEasterDate(new Date().getFullYear()).getTime() - 2 * 24 * 60 * 60 * 1000),
      end: new Date(getEasterDate(new Date().getFullYear()).getTime() + 1 * 24 * 60 * 60 * 1000),
      prompt: 'This chapter takes place during Easter.',
    },
    // Labour Day (May 1)
    {
      start: new Date(new Date().getFullYear(), 4, 1),
      end: new Date(new Date().getFullYear(), 4, 1),
      prompt: 'This chapter takes place during 1st of May (Labour Day).',
    },
  ]

export const isDateInRange = ((date: Date, start: Date, end: Date): boolean => {
  return date >= start && date <= end
})

export const getHolidayPrompt = ((): string | null => {
  const now = new Date()

  for (const range of holidayDays) {
    if (isDateInRange(now, range.start, range.end)) {
      const holidayRandomizer = Math.random()
      if (holidayRandomizer <= .33) {
        return range.prompt
      } else {
        return null
      }
    }
  }

  return null
})

function getEasterDate(year: number): Date {
  const a = year % 19
  const b = Math.floor(year / 100)
  const c = year % 100
  const d = Math.floor(b / 4)
  const e = b % 4
  const f = Math.floor((b + 8) / 25)
  const g = Math.floor((b - f + 1) / 3)
  const h = (19 * a + b - d - g + 15) % 30
  const i = Math.floor(c / 4)
  const k = c % 4
  const l = (32 + 2 * e + 2 * i - h - k) % 7
  const m = Math.floor((a + 11 * h + 22 * l) / 451)
  const month = Math.floor((h + l - 7 * m + 114) / 31) // 3 = march, 4 = april
  const day = ((h + l - 7 * m + 114) % 31) + 1
  return new Date(year, month - 1, day) // month is 0-based
}