<template>
  <div>
    <span
      v-if="label"
      class="log-label"
    >{{ label }}</span>
    <v-btn
      v-if="canHideDetails"
      density="compact"
      size="small"
      class="ml-2"
      @click="hideDetails = !hideDetails"
    >
      {{ hideDetails ? 'Show Details' : 'Hide Details' }}
    </v-btn>
    <template
      v-if="hideDetails && canHideDetails"
    >
      <pre
        v-if="data.choices"
        styles="max-width: 1300px"
      >{{ data.choices[0].message.content }}</pre>
      <template
        v-else-if="data.messages"
      >
        <pre
          v-if="data.system"
          styles="max-width: 1300px"
        >{{ data.system }} <br>~~~
        </pre>
        <pre
          v-for="(message, idx) in data.messages"
          :key="idx"
          styles="max-width: 1300px"
        >{{ message.content }} <br>~~~
        </pre>
      </template>
      <template
        v-else-if="data.content"
      >
        <pre
          v-for="(message, idx) in data.content"
          :key="idx"
          styles="max-width: 1300px"
        >{{ message.text }} <br>~~~
        </pre>
      </template>
    </template>
    <pre
      v-else-if="!hideDetails || !canHideDetails"
      styles="max-width: 1300px"
    >{{ data }}</pre>
    <!-- <span>{{ content(entry.data) }}</span> -->
  </div>
</template>
<script setup lang="ts">
import { computed, ref  } from 'vue'
import { LogType } from '../../../../common/src/Types'

const props = defineProps<{
  entry: unknown,
  logType: unknown,
}>()

const data = computed(() => {
  // @ts-ignore
  return (props.logType ? props.entry : props.entry.data) || {}
})
const logType = computed(() => {
  // @ts-ignore
  return (props.logType ? props.logType : props.entry.type) || ''
})

const hideDetails = ref<boolean>(true)
const canHideDetails = computed((): boolean => !!data.value.choices || !!data.value.messages || !!data.value.content)

const label = computed(() => {
  switch (logType.value) {
    case LogType.GENERAL: return ''
    case LogType.CHAT_GPT_REQUEST: return '😎 Our prompt'
    case LogType.CHAT_GPT_RESPONSE: return '🤖 AI\'s answer'
    case LogType.CHAT_GPT_EXCEPTION: return '🛑🤖 AI error'
    case LogType.COQUI_TTS_SYNTH_REQUEST: return '🎤 Generating Coqui TTS voice-over...'
    case LogType.COQUI_TTS_EXCEPTION: return '🛑🎤 Generating Coqui TTS voice-over error'
    // legacy value
    case 'AZURE_TTS_SYNTH_REQUEST': return '🎤🔨 Generating Azure TTS voice-over...'
    // legacy value
    case 'AZURE_TTS_EXCEPTION': return '🛑🎤🔨 Generating Azure TTS voice-over error'
    default: return ''
  }
})

</script>
<style lang="scss" scoped>
.log-label{
  background-color: rgba(255,255,255,0.2);
  padding: 2px 4px;
  border-radius: 4px;
  text-align: center;
}
</style>
